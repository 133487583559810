import React, { FC, ReactNode } from "react";

export interface Props {
  children: ReactNode;
}

const H2: FC<Props> = ({ children }) => {
  return (
    <h2 className="mt-2 mb-8 text-3xl leading-8 font-bold tracking-tight text-blue-600 sm:text-3xl sm:leading-10">
      {children}
    </h2>
  );
};

export default H2;
