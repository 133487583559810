import React, { ChangeEventHandler, FC, memo, useCallback } from "react";

export interface Props {
  onCategorySelect(category: string): void;
  onTermFilter(term: string): void;
}

const BlogFilter: FC<Props> = ({ onTermFilter }) => {
  const inputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onTermFilter(event.currentTarget.value);
    },
    [onTermFilter]
  );

  return (
    <div className="px-4 lg:px-8 py-4 bg-white rounded-lg shadow-lg mx-4 md:mx-8 lg:mx-16 xl:mx-64 -mt-12 lg:-mt-8">
      <label
        className="block text-lg text-teal-500 mb-4"
        htmlFor={"blogFilterInput"}
      >
        Alkalom keresése
      </label>
      <input
        id="blogFilterInput"
        type="text"
        className="border border-gray-100 rounded-lg px-4 py-2 w-full"
        onChange={inputChange}
        placeholder="Keresés..."
      />
    </div>
  );
};

export default memo(BlogFilter);
