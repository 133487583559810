import React, { memo } from "react";

const Footer = () => {
  return (
    <footer className="bg-white w-full py-12 overflow-hidden space-y-8 px-4 md:px-8 lg:px-16 xl:px-64">
      <div className="-mx-5 -my-2 flex flex-wrap justify-center">
        <div className="px-5 py-2 text-base leading-6 text-gray-500 hover:text-gray-900 text-center">
          Ez a weboldal kizárólag az alkalmainkról készült felvételek
          megosztására készült. Ha többet szeretne megtudni a Gödöllői Baptista
          Gyülekezetről, kérem látogassa meg a
          <a
            href="http://godollobaptista.hu"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 ml-1"
          >
            gyülekezet weboldalát ide kattintva
          </a>
          .
        </div>
      </div>
      <div className="mt-8 flex justify-center space-x-6">
        <a
          href="https://www.facebook.com/GodolloBaptist"
          className="text-gray-400 hover:text-gray-500"
          target="_blank"
          rel="noreferrer"
        >
          <span className="sr-only">Facebook</span>
          <svg
            className="h-6 w-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        </a>
        <a
          href="http://godollobaptista.hu"
          className="text-gray-400 hover:text-gray-500"
          target="_blank"
          rel="noreferrer"
        >
          <span className="sr-only">Website</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </a>
      </div>
      <p className="mt-8 text-center text-base leading-6 text-gray-400">
        &copy; 2020 Gödöllői Baptista Gyülekezet
      </p>
    </footer>
  );
};

export default memo(Footer);
