import React, { memo } from "react";

const Iframe = (props: any) => {
  return (
    <div className="max-w-full lg:max-w-full flex flex-row justify-center items-center">
      <iframe {...props} />
    </div>
  );
};

export default memo(Iframe);
