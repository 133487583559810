import React, { FC, memo, useMemo } from "react";
import dayjs from "dayjs";

import { Card } from "components";
import { Frontmatter } from "types/frontmatter";
import { useGroupBy } from "hooks/useGroupByMonth";
import { formatDate } from "utils/formatDate";

export interface CardData extends Frontmatter {
  dateMonth: string;
  id: string;
  tags: string[];
}

const BlogList: FC<{
  data: Array<{
    frontmatter: Frontmatter;
    id: string;
    tags: string[];
  }>;
}> = ({ data }) => {
  const cardData = useMemo<CardData[]>(
    () =>
      data
        .map((item) => ({
          ...item.frontmatter,
          id: item.id,
          dateMonth: dayjs(formatDate(item.frontmatter.date)).format(
            "YYYY. MMMM"
          ),
          tags: item.tags,
        }))
        .sort((a, b) =>
          dayjs(formatDate(a.date)).isBefore(formatDate(b.date)) ? 1 : -1
        ),
    [data]
  );

  const dataByMonth = useGroupBy<CardData>(cardData, "dateMonth");

  return (
    <div className="flex py-4 md:py-8 lg:px-16 lg:py-16 xl:px-64 xl:py-16 flex-col">
      {Object.entries(dataByMonth).map((item, index) => (
        <div key={index} className="my-4">
          <span className="text-gray-200 ml-4 md:ml-8 lg:ml-0 xl:ml-0">
            {item[0]}
            <span className="inline md:hidden lg:hidden xl:hidden ml-1">{`( ${item[1].length}db )`}</span>
          </span>
          <div className="flex flex-row overflow-x-auto md:flex-wrap md:flex-grow md:overflow-x-visible lg:flex-grow lg:overflow-x-visible xl:flex-grow xl:overflow-x-visible px-4 py-4 md:px-4 md:py-4 md:-m-4 lg:-m-4 lg:px-0 lg:py-4 xl:-m-4 xl:px-0 xl:py-0 xl:mt-2">
            {item[1]
              .sort((a, b) =>
                dayjs(formatDate(a.date)).isBefore(formatDate(b.date)) ? -1 : 1
              )
              .map((innerItem) => (
                <div key={innerItem.id} className="m-4">
                  <Card
                    id={innerItem.id}
                    link={innerItem.slug || "/"}
                    title={innerItem.title || ""}
                    slug={innerItem.slug || ""}
                    date={innerItem.date || ""}
                    tags={innerItem.tags}
                  />
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(BlogList);
