import React, { FC, memo, useMemo } from "react";

export interface Props {
  children: string;
}

const Scripture: FC<Props> = ({ children }) => {
  const childrenArray = useMemo(() => React.Children.toArray(children), [children]);
  
  const content = useMemo(() => childrenArray.map((item, index, originalArray) => index === originalArray.length -1 ? item.split("$")[0] : item ), [children]);
  const reference = useMemo(() => childrenArray[childrenArray.length - 1].split("$")[1], [children]);

  return (
    <div className="px-4 py-4 bg-gray-50 rounded-md mb-16">
      <section className="leading-6 lg:text-xl lg:leading-8 text-green-600">
        {content}
      </section>
      <footer className="text-right text-gray-400">{reference}</footer>
    </div>
  );
};

export default memo(Scripture);
