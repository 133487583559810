import React, { FC, memo } from "react";

import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import { Meta } from "types/meta";

export interface Props {
  meta: Meta;
}

const HomeHeader: FC<Props> = ({ meta }) => {
  const data = useStaticQuery<{
    file: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  }>(
    graphql`
      query HomeHeaderQuery {
        file(relativePath: { eq: "next-stream.jpg" }) {
          id
          childImageSharp {
            fluid(maxWidth: 2000) {
              sizes
              src
              srcSet
              presentationWidth
              presentationHeight
              originalName
              originalImg
              base64
              aspectRatio
            }
          }
        }
      }
    `
  ).file.childImageSharp.fluid;

  return (
    <header className="w-full h-128 bg-blue-500">
      <div className="h-full mx-8 lg:mx-16  xl:mx-64 -mt-8 flex flex-col md:flex-row justify-center items-center">
        <a href={meta.nextStream.link}>
          <h1 className="text-center md:text-left lg:text-left mt-2 mb-8 text-lg md:text-xl lg:text-3xl leading-8 font-extrabold tracking-tight text-gray-50 sm:leading-10 md:mr-8 lg:mr-8 cursor-pointer hover:underline">
            A következő istentiszteletünk ide kattintva érhető el.
          </h1>
        </a>
        <a href={meta.nextStream.link}>
          <div
            className="xs:max-w-full sm:max-w-lg md:max-w-lg lg:max-w-xl rounded-lg hover:shadow-xl cursor-pointer"
            style={{ minWidth: "300px" }}
          >
            <Img
              fluid={data}
              alt="A következő istentiszteletünk ide kattintva érhető el."
            />
          </div>
        </a>
      </div>
    </header>
  );
};

export default memo(HomeHeader);
