import React, { memo, FC, useCallback } from "react";
import dayjs from "dayjs";
import { Link } from "gatsby";

import { useCardColor } from "../../hooks/useCardColor";
import { Props } from "./Card.interfaces";
import { formatDate } from "utils/formatDate";

const Card: FC<Props> = ({ title, date, link, slug, tags }) => {
  const color = useCardColor(`${slug}`);

  const capitalize = useCallback<(text: string) => string>(
    (text) => text.charAt(0).toUpperCase() + text.slice(1),
    []
  );

  return (
    <article className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow hover:shadow-lg horizontal-list transition duration-100 ease-in flex-basis-0 w-200px">
      <Link to={link}>
        <div className="flex-1 flex flex-col p-8">
          <div
            className={`w-32 h-32 flex-shrink-0 mx-auto bg-brand-${color} rounded-full shadow-inner flex justify-center items-center flex-col`}
          >
            {date && (
              <>
                <span className="text-gray-50">
                  {capitalize(dayjs(formatDate(date)).format("MMM DD."))}
                </span>
                <span className="text-gray-50">
                  {dayjs(formatDate(date)).format("HH:mm")}
                </span>
              </>
            )}
          </div>
          <span className="mt-6 text-gray-900 text-lg leading-5 font-medium">
            {title || date}
          </span>
        </div>
      </Link>
      {!!tags && tags.length > 0 && (
        <div className="flex p-8">
          {tags.map((item, index) => (
            <div key={index} className="w-1/2">
              <span className="bg-blue-600 text-gray-50 rounded-lg flex justify-center items-center py-1 px-2">
                {item}
              </span>
            </div>
          ))}
        </div>
      )}
    </article>
  );
};

export default memo(Card);
