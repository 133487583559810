export function useGroupBy<T>(
  data: T[],
  accessor: keyof T
): Record<string, T[]> {
  return data.reduce((groups: { [key: string]: T[] }, item) => {
    const val = item[accessor];
    groups[`${val}`] = groups[`${val}`] || [];
    groups[`${val}`].push(item);
    return groups;
  }, {});
}
