import React, { FC, useMemo } from "react";
import Helmet from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { Props, SEOQuery } from "./seo.interfaces";

const SEO: FC<Props> = ({ title }) => {
  const data = useStaticQuery<SEOQuery>(
    graphql`
      query SEOQuery {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const titleTemplate = useMemo(() => `%s | ${data.site.siteMetadata.title}`, [
    data.site.siteMetadata.title,
  ]);

  const htmlAttributes = useMemo(
    () => ({
      lang: "hu",
    }),
    []
  );

  const defaultMeta = useMemo(
    () => [
      {
        name: `description`,
        content: data.site.siteMetadata.description,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: data.site.siteMetadata.description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: data.site.siteMetadata.author,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: data.site.siteMetadata.description,
      },
    ],
    [data.site.siteMetadata.description, data.site.siteMetadata.author]
  );

  const finalMeta = useMemo(() => [...defaultMeta], [defaultMeta]);

  return (
    <Helmet
      htmlAttributes={htmlAttributes}
      title={title}
      titleTemplate={titleTemplate}
      meta={finalMeta}
    />
  );
};

export default SEO;
