import React, { FC } from "react";

const Layout: FC = ({ children }) => {
  return (
    <div>
      <main className="bg-gray-50">{children}</main>
    </div>
  );
};

export default Layout;
